import React, { useState, useRef, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faArrowRight,
  faTrash,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { TailSpin } from "react-loader-spinner";
import { RootState } from "../Store/store";
import Header from "../../components/Header";
import { getAllDealersHandler } from "../Store/slices/dealerSlice";
import { userSelectedDealerHandler } from "../Store/slices/authSlice";
import {
  addScratchVideoHandler,
  deleteScratchDetectVideoHandler,
  getScratchDetectVideoHandler,
  getScratchVideoDetailsHandler,
  submitScratchDetectVideoUrlsHandler,
  videoUploadToS3ForScratchHandler,
} from "../Store/slices/videoSlice";
import VideoUrlModalComponent from "../../components/videoUrlModal";
import ReactPaginate from "react-paginate";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Tooltip } from "@mui/material";
import moment from "moment";
import {
  ADD_SCRATCH_VIDEO,
  DELETE_SCRATCH_VIDEO,
  FULFILLED,
  REJECTED,
  SCRATCH_VIDEO_UPLOAD_S3,
} from "../Store/actions";
import DeleteDialogBox from "../../components/VideoDeleteModal";
import VideoPlayerModal from "../../components/VideoPlayerModal";

const ScratchDetect = () => {
  const { t } = useTranslation("common");
  const [selectedDealerUuid, setSelectedDealerUuid] = useState<any>("");
  const [trigger, setTrigger] = useState(false);
  const dispatch = useDispatch();
  const [fileName, setFileName] = useState<string>("");
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const { showLoader } = useSelector((state: RootState) => state.common);
  const MAX_FILE_SIZE = 1024 * 1024 * 500; // 100MB
  const SUPPORTED_FORMATS = [
    "video/mp4",
    "video/avi",
    "video/mov",
    "video/quicktime",
  ];
  const [loader, setLoader] = useState<boolean>(false);
  const { user, selectedDealerId, selectedBrandId } = useSelector(
    (state: RootState) => state.auth
  );
  const token = localStorage.getItem("token");
  const [videoUrls, setVideoUrls] = useState<string>("");
  const [error, setError] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>("");
  const user_id = user?.user?.uuid ?? user?.uuid;
  const [loading, setLoading] = useState<boolean>(false);
  const userId = user?.user?.uuid;
  const { paginationData } = useSelector((state: RootState) => state.video);
  const [videos, setVideos] = useState<any>([]);
  const [showModal, setShowModal] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [totalRecords, setTotalRecords] = useState(paginationData?.totalItems);
  const [paginationSize, setPaginationSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState(paginationData?.currentPage);
  const [alreadyFetchedDataPageNumber, setAlreadyFetchedDataPageNumber] =
    useState<any>({});
  const PER_PAGE = 10;
  const [showVideoDeleteModal, setShowVideoDeleteModal] = useState(false);
  const [isProcessedLink, setIsProcessedLink] = useState(false);
  const [deleteVideoId, setDeleteVideoId] = useState("");
  const [selectedVideo, setSelectedVideo] = useState<any>(null);

  useEffect(() => {
    const dealerUuid = selectedDealerId ?? "0";
    setSelectedDealerUuid(dealerUuid);
    const brandName = selectedBrandId ?? "";
    dispatch(getAllDealersHandler(brandName)).then((res: any) => {
      if ("0" === dealerUuid && res.payload) {
        dispatch(userSelectedDealerHandler(res?.payload[0]?.uuid));
        setSelectedDealerUuid(res.payload[0]?.uuid);
      }
    });
  }, []);

  const handleDeleteVideo = async (uuid: string) => {
    setShowVideoDeleteModal(true);
    setDeleteVideoId(uuid);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event?.target?.files) {
      const file = event?.target?.files[0];
      validateAndUpload(file);
    }
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (event?.dataTransfer?.files) {
      const file = event?.dataTransfer?.files[0];
      validateAndUpload(file);
    }
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const handleClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const validateAndUpload = (file: File) => {
    if (file?.size > MAX_FILE_SIZE) {
      toast.error(t("commonToastMsg.fileSizeLimit"), {
        position: "top-right",
        autoClose: 2000,
      });
      return;
    }

    if (!SUPPORTED_FORMATS.includes(file.type)) {
      toast.error(t("commonToastMsg.fileFormat"), {
        position: "top-right",
        autoClose: 2000,
      });
      return;
    }
    setFileName(file.name);
    handleUpload(file);
  };

  const handleUpload = async (file: File) => {
    setLoading(true);

    const userId = user_id;
    const data = {
      video: file,
      userId,
    };
    dispatch(videoUploadToS3ForScratchHandler(data))
      .then(async (res: any) => {
        setLoader(true);
        if (res?.type === `${SCRATCH_VIDEO_UPLOAD_S3}/${FULFILLED}`) {
          const putUrl = res.payload.putUrl;
          const getUrl = res.payload.getUrl;
          const response = await fetch(putUrl, {
            method: "PUT",
            headers: {
              "Content-Type": file.type,
            },
            body: file,
          });
          let videoUpload = false;
          if (200 === response.status) {
            videoUpload = true;
          }
          const apiData = {
            userId: userId,
            videoUuid: res.payload.videoUuid,
            fileName: res.payload.fileName,
            videoUpload,
            uuid: selectedDealerId,
            fileType: file.type,
            getUrl: getUrl,
          };
          dispatch(addScratchVideoHandler(apiData)).then(async (res: any) => {
            setLoading(false);
            if (res?.type === `${ADD_SCRATCH_VIDEO}/${FULFILLED}`) {
              getAllScratchDetectVideo(1, selectedDealerId);
              setFileName("");
              if (fileInputRef.current) {
                fileInputRef.current.value = ""; // Reset the input field
              }
              toast.success(t("VideoProcess.videoUploadSuccess"), {
                position: "top-right",
                autoClose: 2000,
              });
            } else {
              toast.error(res?.payload?.response?.data?.message, {
                position: "top-right",
                autoClose: 2000,
              });
            }
          });
        } else if (res?.type === `${SCRATCH_VIDEO_UPLOAD_S3}/${REJECTED}`) {
          setLoading(false);
          toast.error(res?.payload?.response?.data?.message, {
            position: "top-right",
            autoClose: 2000,
          });
        }
      })
      .catch((error: any) => {
        setLoading(false);
        toast.error(t("VideoProcess.videoUploadFailed"), {
          position: "top-right",
          autoClose: 2000,
        });
      });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setShowModal(true);
  };

  const paginateData = (data: any) => {
    setVideos(data);
    const firstSet = data?.slice(0, PER_PAGE);
    setVideos(firstSet);
  };
  const getAllScratchDetectVideo = async (pageNumber: any, dealerUuid: any) => {
    const brandName = selectedBrandId;
    const data = {
      userid: userId,
      pageNumber: 1,
      uuid: dealerUuid,
      brandName: brandName,
    };
    await dispatch(getScratchDetectVideoHandler(data))
      .then((res: any) => {
        setVideos(res?.payload?.data);
        const videoData = res?.payload?.data;
        setTotalRecords(res?.payload?.totalItems);
        updateData(pageNumber, res?.payload?.data);
        setVideos(videoData);
        paginateData(videoData);
        setCurrentPage(0);
        setPageNumber(pageNumber);
      })
      .catch((err: any) => {
        console.log("err", err);
      });
  };
  const updateData = (pageNumber: number, newData: any) => {
    setAlreadyFetchedDataPageNumber((prevState: any) => ({
      ...prevState,
      [pageNumber]: newData,
    }));
  };

  const handleConfirm = async () => {
    setShowModal(false);
    setLoading(true);
    const urlsArray = videoUrls.split(",").map((url) => url.trim());
    let urlfinalArr = [];
    let newurlarr = urlsArray.sort((a, b) => {
      return b.length - a.length;
    });
    for (let url of newurlarr) {
      if (url.length > 0) {
        urlfinalArr.push(url);
      }
    }
    const data = {
      userId: user_id,
      urlsArray: urlfinalArr,
      uuid: selectedDealerId,
    };
    await dispatch(submitScratchDetectVideoUrlsHandler(data))
      .then(async (res: any) => {
        setLoading(false);
        if (
          res.type == "submitScratchVideoUrls/fulfilled" &&
          res.payload != ""
        ) {
          const data = {
            userid: user_id,
            pageNumber: 1,
            uuid: selectedDealerId,
            brandName: selectedBrandId,
          };
          getAllScratchDetectVideo(1, selectedDealerId);
          toast.success(t("VideoProcess.videoUrlSuccess"), {
            position: "top-right",
            autoClose: 2000,
          });
        } else {
          toast.error(t("VideoProcess.videoUrlFail"), {
            position: "top-right",
            autoClose: 2000,
          });
        }
      })
      .catch((err: any) => {
        toast.error(t("VideoProcess.videoUrlFail"), {
          position: "top-right",
          autoClose: 2000,
        });
      });
    // Clear the textarea
    setVideoUrls("");
  };
  const isValidUrl = (url: string) => {
    const videoUrlRegex =
      /^(https?:\/\/)?((www\.)?(youtube\.com|youtu\.?be)\/(watch\?v=|embed\/|v\/|.+\?v=)?[a-zA-Z0-9_-]{11}|vimeo\.com\/\d+|([a-zA-Z0-9_-]+\.)+[a-zA-Z]{2,}\/.*\.(mp4|webm|ogg|avi|mov|wmv|flv|mkv))$/i;
    return videoUrlRegex.test(url);
  };

  useEffect(() => {
    if (videoUrls.trim() === "") {
      setError(false);
      setErrorMsg("");
      return;
    }

    const urlsArray = videoUrls
      .split(",")
      .map((url) => url.trim())
      .filter((url) => url !== "");

    if (urlsArray.length > 10) {
      setError(true);
      setErrorMsg(t("VideoProcess.videoUrlCountValidation"));
    } else if (urlsArray.some((url) => !isValidUrl(url))) {
      setError(true);
      setErrorMsg(t("VideoProcess.videoUrlInvalidValidation"));
    } else {
      setError(false);
      setErrorMsg("");
    }
  }, [videoUrls]);

  useEffect(() => {
    const data = {
      userid: user_id,
      pageNumber,
      selectedDealer: selectedDealerId,
    };
    getAllScratchDetectVideo(1, selectedDealerId);
  }, [trigger]);

  const setTriggerfn = (data: any) => {
    setTrigger(true);
    setTimeout(() => {
      setTrigger(false);
    }, 500);
    const dealerUuid = selectedDealerId ?? "0";
    setSelectedDealerUuid(dealerUuid);
  };
  useEffect(() => {
    const selectedDealerUuid = selectedDealerId ?? "0";
    getAllScratchDetectVideo(pageNumber, selectedDealerUuid);
  }, [token]);

  useEffect(() => {
    if (showLoader) {
      setLoader(false);
    }
  }, [showLoader]);
  const toTitleCase = (str: string) => {
    if (!str) return "";
    return str.toLowerCase().replace(/\b\w/g, (s) => s.toUpperCase());
  };

  //FOR FUTURE USE
  //   const handleRetry = async (uuid: string) => {
  //     await dispatch(retryScratchedVideoAnalysisHandler(uuid))
  //       .then(async (res: any) => {
  //         toast.success(t("VideoProcess.videoProcessing"), {
  //           position: "top-right",
  //           autoClose: 2000,
  //         });
  //         const brandName = selectedBrandId;
  //         const data = {
  //           userid: userId,
  //           pageNumber: 1,
  //           uuid: selectedDealerId,
  //           brandName: brandName,
  //         };

  //         await dispatch(getScratchDetectVideoHandler(data))
  //           .then((res: any) => {
  //             setVideos(res?.payload?.data);
  //             const videoData = res?.payload?.data;
  //             setTotalRecords(res?.payload?.totalItems);
  //             updateData(pageNumber, res?.payload?.data);
  //             setVideos(videoData);
  //             paginateData(videoData);
  //             setCurrentPage(0);
  //           })
  //           .catch((err: any) => {
  //             console.log("err", err);
  //             toast.error(err.message, {
  //               position: "top-right",
  //               autoClose: 2000,
  //             });
  //           });
  //       })
  //       .catch((err: any) => {
  //         toast.error(err.message, {
  //           position: "top-right",
  //           autoClose: 2000,
  //         });
  //       });
  //   };

  const onHandleFetchMore = (selected: any) => {
    const newPageNumber = selected + 1;
    if (videos) {
      setPageNumber(newPageNumber);
    }
    getAllScratchDetectVideo(newPageNumber, selectedDealerId);

    setPageNumber(newPageNumber);
    setCurrentPage(newPageNumber);
  };

  const deleteConfirmation = (uuid: string) => {
    setLoading(true);
    const data = {
      uuid,
      userId: user_id,
    };
    dispatch(deleteScratchDetectVideoHandler(data)).then(
      async (deleteRes: any) => {
        if (deleteRes.payload.statusCode == 200) {
          const brandName = selectedBrandId;
          const data = {
            userid: userId,
            pageNumber: 1,
            uuid: selectedDealerId,
            brandName: brandName,
          };
          let pageNo = pageNumber;
          await dispatch(getScratchDetectVideoHandler(data))
            .then((res: any) => {
              setVideos(res?.payload?.data);
              const videoData = res?.payload?.data;
              setTotalRecords(res?.payload?.totalItems);
              updateData(pageNumber, res?.payload?.data);
              setVideos(videoData);
              paginateData(videoData);
              setPageNumber(pageNo);
              setCurrentPage(pageNo);
              setLoading(false);
            })
            .catch((err: any) => {
              console.log("err", err);
            });
          if (deleteRes.type == `${DELETE_SCRATCH_VIDEO}/${FULFILLED}`) {
            toast.success(t("VideoProcess.videoDelete"), {
              position: "top-right",
              autoClose: 2000,
            });
          } else {
            setLoading(false);
            toast.error(
              deleteRes?.payload?.message ??
                t("VideoProcess.videoDeleteFailed"),
              {
                position: "top-right",
                autoClose: 2000,
              }
            );
          }
        } else {
          setLoading(false);
          toast.error(
            deleteRes?.payload?.message ?? t("VideoProcess.videoDeleteFailed"),
            {
              position: "top-right",
              autoClose: 2000,
            }
          );
        }
      }
    );
  };

  const [showVideoModal, setShowVideoModal] = useState(false);

  const handleOpenVideoModal = async (video: any) => {
    try {
      await dispatch(getScratchVideoDetailsHandler(video))
        .then((res: any) => {
          setSelectedVideo(res.payload);
        })
        .catch((err: any) => {
          console.log("err", err);
        });
      setShowVideoModal(true);
    } catch (error) {
      toast.error("Failed to generate video URL. Please try again.", {
        position: "top-right",
        autoClose: 2000,
      });
    }
  };
  const handleCloseVideoModal = () => {
    setShowVideoModal(false);
  };

  return (
    <div className="min-vh-100 container-fluid">
      <div className="mx-lg-5">
        <div className="mt-5 mb-4 pb-2">
          <Header setTrigger={setTriggerfn} />
        </div>
        <div className="row gy-4 mb-5">
          <div className="col-lg-6">
            <div className="upload-area">
              <div className="text-center">
                {loader && (
                  <div className="loader-overlay">
                    <div className="loader-container">
                      <TailSpin color="#00BFFF" height={80} width={80} />
                    </div>
                  </div>
                )}
                <div
                  onDrop={handleDrop}
                  onDragOver={handleDragOver}
                  onClick={handleClick}>
                  <input
                    type="file"
                    accept="video/*"
                    onChange={handleFileChange}
                    ref={fileInputRef}
                    style={{ display: "none" }}
                  />
                  <div>
                    <div className="my-4">
                      <FontAwesomeIcon
                        icon={faUpload}
                        size="2x"
                        className="mb-3"
                      />
                      <p className="fs-4 fw-bold">
                        {t("Dashboard.dragDropPlaceholder")}
                      </p>
                      <div className="text-black">
                        {t("Dashboard.supportedPropsPlaceholder")}
                      </div>
                    </div>
                    <div>
                      <button className="btn btn-primary custom-btn">
                        {t("Dashboard.browseFile")}
                      </button>
                    </div>
                    {fileName && (
                      <p className="mb-0 mt-2">
                        {t("Dashboard.selectFile")}: {fileName}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <form onSubmit={handleSubmit} className="submit-url-area">
              <div className="flex-fill">
                <textarea
                  id="video_urls"
                  name="video_urls"
                  placeholder={t("Dashboard.enterUrl")}
                  className="form-control submit-url-textarea"
                  rows={5}
                  value={videoUrls}
                  onChange={(e) => setVideoUrls(e.target.value)}
                />
                {error && (
                  <div className="error-message" style={{ color: "red" }}>
                    {errorMsg}
                  </div>
                )}
              </div>
              <div className="text-end">
                <button
                  type="submit"
                  disabled={error || videoUrls.trim() == ""}
                  className="btn btn-primary custom-btn">
                  Submit Urls
                </button>
              </div>
            </form>
          </div>
        </div>

        <div>
          <TableContainer component={Paper} className="custom-table mb-5">
            <Table
              sx={{ minWidth: 550 }}
              size="small"
              aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{ backgroundColor: "#3483c8", fontWeight: "bold" }}>
                    Unique ID
                  </TableCell>
                  <TableCell
                    sx={{
                      backgroundColor: "#3483c8",
                      fontWeight: "bold",
                      padding: "0 8px",
                    }}>
                    Video URL
                  </TableCell>
                  <TableCell
                    sx={{
                      backgroundColor: "#3483c8",
                      fontWeight: "bold",
                      padding: "0 8px",
                    }}>
                    Processed Video URL
                  </TableCell>
                  <TableCell
                    sx={{
                      backgroundColor: "#3483c8",
                      fontWeight: "bold",
                      padding: "0 8px",
                    }}>
                    Status
                  </TableCell>
                  <TableCell
                    sx={{
                      backgroundColor: "#f5f5f5",
                      fontWeight: "bold",
                      padding: "0 8px",
                    }}>
                    {t("constants.updatedAt")}
                  </TableCell>
                  <TableCell
                    sx={{ backgroundColor: "#f5f5f5", fontWeight: "bold" }}>
                    {t("constants.action")}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {videos && videos.length > 0 ? (
                  videos?.map((row: any) => {
                    return (
                      <TableRow key={row.uuid} className="mb-4">
                        <TableCell sx={{ padding: "8px" }}>
                          <div>{row.uuid}</div>
                        </TableCell>
                        <TableCell sx={{ padding: "0 8px" }}>
                          <a
                            href="#"
                            className="link-color"
                            onClick={() => {
                              handleOpenVideoModal(row?.uuid);
                              setIsProcessedLink(false);
                            }}>
                            {
                              row?.storageLink
                                ?.split("/")
                                [row?.storageLink.split("/").length - 1]?.split(
                                  "?"
                                )[0]
                            }
                          </a>
                        </TableCell>
                        <TableCell sx={{ padding: "0 8px" }}>
                          {row.processStatuses.map(
                            (data: any, index: number) => (
                              <span key={data?.uuid}>
                                {data?.modalName}:&nbsp;
                                {data?.status === "SUCCESSFUL" ? (
                                  <a
                                    href="#"
                                    className="link-color"
                                    onClick={() => {
                                      handleOpenVideoModal(data?.uuid);
                                      setIsProcessedLink(true);
                                    }}>
                                    {
                                      data?.processedLink
                                        ?.split("/")
                                        [
                                          data?.processedLink.split("/")
                                            .length - 1
                                        ]?.split("?")[0]
                                    }
                                  </a>
                                ) : (
                                  "-"
                                )}
                                <br />
                              </span>
                            )
                          )}
                        </TableCell>
                        <TableCell sx={{ padding: "0 8px" }}>
                          {row.processStatuses.map((data: any, index: any) => (
                            <span key={data?.uuid}>
                              {data?.modalName}: {toTitleCase(data?.status)}
                              <br />
                            </span>
                          ))}
                        </TableCell>
                        <TableCell sx={{ padding: "0 8px" }}>
                          {moment(row?.updatedAt).format("DD-MM-YYYY hh:mm:ss")}
                        </TableCell>
                        <TableCell sx={{ padding: "8px" }}>
                          <div className="d-flex align-items-center justify-content-center gap-3">
                            {/* //for future use */}
                            {/* {row.status == FAILED &&
                                                        <Tooltip placement="top" title={t('constants.retry')}>
                                                            <button className="btn p-0"
                                                                onClick={() => { handleRetry(row.uuid) }}
                                                            >
                                                                <FontAwesomeIcon icon={faRotateForward} className="fs-6" />
                                                            </button>
                                                        </Tooltip>} */}
                            <Tooltip
                              placement="top"
                              title={t("constants.delete")}>
                              <button
                                className="btn p-0"
                                onClick={() => {
                                  handleDeleteVideo(row.uuid);
                                }}>
                                <FontAwesomeIcon
                                  icon={faTrash}
                                  color="red"
                                  className="fs-6"
                                />
                              </button>
                            </Tooltip>
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={7} style={{ textAlign: "center" }}>
                      No videos found
                    </td>
                  </tr>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>

        <VideoUrlModalComponent
          isOpen={showModal}
          onClose={handleCloseVideoModal}
          modalHeading={"Video Urls"}
          onSubmit={handleConfirm}>
          {videoUrls
            .split(",")
            .map((url) => url.trim())
            .filter((url) => url !== "")}
        </VideoUrlModalComponent>
      </div>
      <div className="d-flex align-items-center justify-content-end">
        {videos && videos?.length > 0 && (
          <ReactPaginate
            pageCount={Math.ceil(totalRecords / paginationSize)}
            previousLabel={
              <FontAwesomeIcon icon={faArrowLeft} className="text-black" />
            }
            nextLabel={
              <FontAwesomeIcon icon={faArrowRight} className="text-black" />
            }
            onPageChange={({ selected }) => onHandleFetchMore(selected)}
            containerClassName={"pagination"}
            previousLinkClassName={"pagination__link"}
            nextLinkClassName={"pagination__link"}
            disabledClassName={"pagination__link--disabled"}
            activeClassName={"pagination__link--active"}
            forcePage={pageNumber == 0 ? pageNumber : pageNumber - 1}
          />
        )}
        <VideoPlayerModal
          isOpen={showVideoModal}
          onClose={handleCloseVideoModal}
          video={selectedVideo}
          isProcessedLink={isProcessedLink}
        />
        <DeleteDialogBox
          show={showVideoDeleteModal}
          clickOk={() => {
            deleteConfirmation(deleteVideoId);
            setShowVideoDeleteModal(false);
          }}
          clickCancel={() => {
            setShowVideoDeleteModal(false);
          }}
          color={"btn-danger"}
          btncancel={t("constants.cancel")}
          btnyes={t("constants.delete")}
          deleteText={t("VideoProcess.videoDeleteConfirmation")}
        />
      </div>
    </div>
  );
};

export default ScratchDetect;
