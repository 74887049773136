import { TextField } from '@mui/material'
import { ErrorMessage, Field } from 'formik'

interface FormFieldProps {
    name: string;
    fieldAs: string;
    title?: string;
    isDisabled?: boolean;
    options?: any;
    isInExist?:boolean;
    getTotalForIn?: () => number | undefined;
  }

const FormField:React.FC<FormFieldProps> = ({ name, fieldAs, title, isDisabled, options,isInExist,getTotalForIn }) => {
    return (
        <div className="form-field">
            <label htmlFor={name}>{title}: {isInExist && `(Per Keyword) Total: ${getTotalForIn}`}</label>
            {
                fieldAs == 'select' ?
                    <Field
                        as="select"
                        name={name}
                        className="form-select"
                        disabled={isDisabled}
                    >
                        {options.map(({ value, label }: any) => (
                            <option key={value} value={value}>
                                {label}
                            </option>
                        ))}
                    </Field>
                    :
                    <Field
                        as={TextField}
                        fullWidth
                        id={name}
                        name={name}
                        size="small"
                        variant="outlined"
                        disabled={isDisabled}
                    />
            }
            <ErrorMessage
                name={name}
                component="div"
                className="text-danger txt-error"
            />
        </div>
    )
}

export default FormField