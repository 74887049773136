import { Modal } from "react-bootstrap";
import { DUMMY_THUMBNAIL_URL } from '../common/commonConstants';
import { useTranslation } from 'react-i18next';
 
interface ImageModalProps {
    isOpen: boolean;
    onClose: () => void;
    image: any;
    isProcessedLink?: boolean;
}
 
const ThumbnailImageView: React.FC<ImageModalProps> = ({ isOpen, onClose, image }) => {
    const { t } = useTranslation('common');
    return (
        <>
            <Modal className="video-details-modal" show={isOpen} size="lg" centered>
                <Modal.Header>
                    <h5 className="modal-title">
                        Side Wall Image
                    </h5>
                    <button
                        type="button"
                        className="btn-close"
                        aria-label="Close"
                        onClick={onClose}
                    >
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <div className="text-center"
                    ><img className="thumbnail-modal-image" src={image} alt='' title="Image Thumbnails"
                        onError={(thumbnail: any) => {
                            thumbnail.target.src = DUMMY_THUMBNAIL_URL;
                        }}></img></div>
                </Modal.Body>
            </Modal>
        </>
    );
};
 
export default ThumbnailImageView;