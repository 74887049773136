import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../Store/store";
import { deleteTireHandler, tireDetailsByIdHandler, tireDetailsHandler } from "../Store/slices/tireScanSlice";
import { ThunkDispatch } from "@reduxjs/toolkit";
import moment from "moment";
import TireTreadScanModal from "../../components/TireScanViewModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight, faEye, faRotateForward, faTrash } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import ProgressBar from "@ramonak/react-progress-bar";
import ReactPaginate from "react-paginate";
import DeleteDialogBox from "../../components/VideoDeleteModal";

const TireDetect = () => {
    const { t } = useTranslation("common");
    const dispatch: ThunkDispatch<object, object, any> = useDispatch();
    const { selectedBrandId, selectedDealerId } = useSelector(
        (state: RootState) => state.auth
    );

    const { paginationData }: any = useSelector((state: RootState) => state.auth);
    const [pageNumber, setPageNumber] = useState(1);
    const [tireData, setTireData] = useState<any>([]);
    const [trigger, setTrigger] = useState(false);
    const [showTreadModal, setShowTreadModal] = useState(false);
    const [uuid, setUuid] = useState<any>('');
    const [apiResponse, setApiResponse] = useState<any>({});
    const [loading, setLoading] = useState<any>(false);
    const [totalRecords, setTotalRecords] = useState(paginationData?.totalItems);
    const [currentPage, setCurrentPage] = useState(1);
    const [showTireDeleteModal, setShowTireDeleteModal] = useState(false);
    
    const limit = 10;
    const setTriggerfn = () => {
        setTimeout(() => {
            setTrigger(false);
        }, 500);
        setTrigger(true);
    };

    const fetchTireData = async () => {
        setLoading(true);
        try {
            const data: any = {
                brandUuid: selectedBrandId,
                dealerUuid: selectedDealerId,
                pageNumber: pageNumber,
                limit: limit,
            };
            const result: any = await dispatch(tireDetailsHandler(data));
            if (result.type === "getTireScan/fulfilled") {
                const { data, totalItems, currentPage } = result.payload;
                setTireData(data);
                setTotalRecords(totalItems);
                setCurrentPage(currentPage);
            }
        } catch (error: any) {
            throw new Error(error || "Failed to fetch tire data.");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchTireData();
    }, [trigger, pageNumber]);

    const onHandleFetchMore = (selected: any) => {
        const newPageNumber = selected + 1;
        if (tireData) {
            setPageNumber(newPageNumber);
            setCurrentPage(newPageNumber);
        }
    };
    const fetchTireScanData = async () => {
        setLoading(true);
        try {
            const data = { uuid: uuid };
            const result = await dispatch(tireDetailsByIdHandler(data));
            if (result.type === "getTireScanTypeDetail/fulfilled") {
                const { data } = result.payload;
                setApiResponse(data);
            }
        } catch (error: any) {
            throw error;
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (showTreadModal) {
            fetchTireScanData();
        }
    }, [showTreadModal]);

    const handleDeleteTire = async (uuid: string) => {       
            setUuid(uuid);
            setShowTireDeleteModal(true);
    }

    const handleSideWallTreadModal = (uuid: string) => {
        setUuid(uuid);
        setShowTreadModal(true);
    }

    const toTitleCase = (str: string) => {
        if (!str) return "";
        return str.toLowerCase().replace(/\b\w/g, (s) => s.toUpperCase());
    };

    return (
        <>
            <div className="min-vh-100 container-fluid">
                <div className="mx-lg-5">
                    <div className="mt-5 mb-4 pb-2">
                        <Header setTrigger={setTriggerfn} />
                    </div>
                    <div>
                        <TableContainer component={Paper} className="custom-table mb-5">
                            <Table
                                sx={{ minWidth: 550 }}
                                size="small"
                                aria-label="a dense table">
                                <TableHead>
                                    <TableRow>
                                    <TableCell
                                            sx={{ backgroundColor: "#3483c8", fontWeight: "bold", padding: "0 8px" }} >
                                            {t("constants.scanId")}
                                        </TableCell>
                                        <TableCell
                                            sx={{ backgroundColor: "#3483c8", fontWeight: "bold", padding: "0 8px" }} >
                                            {t("constants.licensePlate")}
                                        </TableCell>
                                        <TableCell
                                            sx={{
                                                backgroundColor: "#3483c8",
                                                fontWeight: "bold",
                                                padding: "0 8px",
                                            }}>
                                            {t("constants.status")}
                                        </TableCell>
                                        <TableCell
                                            sx={{
                                                backgroundColor: "#3483c8",
                                                fontWeight: "bold",
                                                padding: "0 8px",
                                            }}>
                                            {t("constants.progress")}
                                        </TableCell>
                                        <TableCell
                                            sx={{
                                                backgroundColor: "#3483c8",
                                                fontWeight: "bold",
                                                padding: "0 8px",
                                            }}>
                                            {t("constants.updatedAt")}
                                        </TableCell>
                                        <TableCell
                                            sx={{
                                                backgroundColor: "#3483c8",
                                                fontWeight: "bold",
                                                padding: "0 8px",
                                                textAlign: "center"
                                            }}>
                                            Action
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {tireData && tireData.length > 0 ? (
                                        tireData.map((row: any, index: any) => (
                                            <TableRow key={index}>
                                             <TableCell sx={{ padding: "8px" }}>{row.uuid !== '' ? row.uuid : '-'}</TableCell>
                                                <TableCell sx={{ padding: "8px" }}>{row.licensePlate !== '' ? row.licensePlate : '-'}</TableCell>
                                                <TableCell sx={{ padding: "8px" }}>{toTitleCase(row?.status)}</TableCell>
                                                <TableCell sx={{ padding: "8px" }}>
                                                    <div style={{ width: '50%' }}>
                                                        <ProgressBar
                                                            bgColor="#3483c8"
                                                            completed={row?.progress}
                                                            className="status-progressbar"
                                                        />
                                                    </div>
                                                </TableCell>
                                                <TableCell sx={{ padding: "8px" }}>{moment(row?.updatedAt).format("DD-MM-YYYY hh:mm:ss")}</TableCell>
                                                <TableCell sx={{ padding: "0px 8px", textAlign: "center" }}>
                                                    {/* Retry option hide for now
                                                    <button className="btn">
                                                    <FontAwesomeIcon icon={faRotateForward} color="grey" className="fs-6 me-4" />
                                                    </button> */}
                                                    <button className="btn" onClick={() => handleSideWallTreadModal(row.uuid)}>
                                                        <FontAwesomeIcon
                                                            icon={faEye}
                                                            className="fs-6 text-primary"
                                                        />
                                                    </button>
                                                    <button className="btn" onClick={() => handleDeleteTire(row.uuid)}>
                                                        <FontAwesomeIcon icon={faTrash} color="red" className="fs-6" />
                                                    </button>
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    ) : (
                                        <TableRow>
                                            <TableCell colSpan={6} sx={{ textAlign: "center", padding: "16px" }}>
                                                No record found
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                    <div className="d-flex align-items-center justify-content-end">
                        {tireData && (
                            <ReactPaginate
                                pageCount={Math.ceil(totalRecords / limit)}
                                previousLabel={<FontAwesomeIcon icon={faArrowLeft} className="text-black" />}
                                nextLabel={<FontAwesomeIcon icon={faArrowRight} className="text-black" />}
                                containerClassName={"pagination"}
                                onPageChange={({ selected }) => onHandleFetchMore(selected)}
                                previousLinkClassName={"pagination__link"}
                                nextLinkClassName={"pagination__link"}
                                disabledClassName={"pagination__link--disabled"}
                                activeClassName={"pagination__link--active"}
                                forcePage={pageNumber === 0 ? pageNumber : pageNumber - 1}
                            />
                        )}
                    </div>
                </div>
            </div>
            <TireTreadScanModal
                showTreadModal={showTreadModal}
                setShowTreadModal={setShowTreadModal}
                apiResponse={apiResponse}
                loading={loading}
                uuid={uuid}
                fetchTireScanData={fetchTireScanData}
            />
            {showTireDeleteModal && (
                <DeleteDialogBox
                    show={showTireDeleteModal}
                    clickOk={async () => {
                        setShowTireDeleteModal(false);
                        setLoading(true);
                        try {
                            const result = await dispatch(deleteTireHandler(uuid));
                            if (result.type === "deletetire/fulfilled") {
                                toast.success(result.payload.message, {
                                    position: "top-right",
                                    autoClose: 2000,
                                });
                                setPageNumber(1);
                                await fetchTireData();
                            }
                        } catch (error) {
                            toast.error("Error deleting tire record");
                        } finally {
                            setLoading(false);
                        }
                    }}                    
                    clickCancel={() => {
                        setShowTireDeleteModal(false);
                    }}
                    color={"btn-danger"}
                    btncancel={"Cancel"}
                    btnyes={"Delete"}
                    deleteText={"Do you want to delete the record?"}
                />
            )}
        </>
    )
}
export default TireDetect;