import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Header from '../../components/Header';
import { RootState } from '../Store/store';
import * as Yup from 'yup';
import { Form, Formik, FieldArray, Field } from 'formik';
import VideoScoringForm from './VideoScoringForm';
import { getVideosScoreAlgorithmByBrandDealersHandler, updateVideoScoringHandler } from '../Store/slices/videoSlice';
import { useNavigate } from 'react-router-dom';
import { FULFILLED, UPDATE_VIDEO_SCORING } from '../Store/actions';
import { toast } from 'react-toastify';
import * as routeConstant from '../../common/routeConstants';
import './VideoScoring.css';
import { baseOptions } from '../../common/common';

const VideoScoring: React.FC = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [initialValues, setInitialValues] = useState({
        baseFormula: 'ZERO',
        data: [
            {
                videoScoringAlgorithmId: 0,
                videoAlgorithmKey: '',
                videoAlgorithmCondition: '',
                videoAlgorithmParams: '',
                videoAlgorithmParamsbetween: 0,
                videoAlgorithmRewardPoint: '',
                videoAlgorithmCustomCalc: '',
                status: 'ENABLED'
            },
        ],
    });
    const [trigger, setTrigger] = useState(false);
    const [totalRewardPoints,setTotalRewardPoints] = useState(0);
    const { user, selectedBrandId, selectedDealerId } = useSelector((state: RootState) => state.auth);

    const validationSchema = Yup.object().shape({
        data: Yup.array().of(
            Yup.object().shape({
                videoAlgorithmKey: Yup.string()
                    .required('Video Algorithm Key is required')
                    .test(
                        'unique-selection',
                        'Words per minute or Longest silence is already selected',
                        function (value: string | undefined) {
                            const { path, options }: any = this;
                            const currentIndex = parseInt(path.match(/\d+/)?.[0] || '0');
                            const { data } = options.context;
                            const keysToValidate = ['words_per_minute', 'longest_silence'];
                            const isDuplicate = data.some(
                                (item: any, index: number) =>
                                    keysToValidate.includes(value || '') &&
                                    item.videoAlgorithmKey === value &&
                                    index !== currentIndex
                            );
                            return !isDuplicate;
                        }
                    ),
                videoAlgorithmCondition: Yup.string()
                    .required('Video Algorithm Condition is required'),
                videoAlgorithmParams: Yup.string()
                    .required('Video Algorithm Params is required')
                    .test(
                        'unique-combination',
                        'Video Algorithm Params should not be the same for identical Key and Condition',
                        function (value: any) {
                            const { path, parent, options }: any = this;
                            const currentIndex = parseInt(path.match(/\d+/)?.[0] || '0');
                            const { data } = options.context;

                            // Check for duplicates with the same Key and Condition
                            const isDuplicate = data.some(
                                (item: any, index: number) =>
                                    index !== currentIndex &&
                                    item.videoAlgorithmKey === parent.videoAlgorithmKey &&
                                    item.videoAlgorithmCondition === parent.videoAlgorithmCondition &&
                                    item.videoAlgorithmParams === value
                            );

                            return !isDuplicate;
                        }
                    ),
                videoAlgorithmRewardPoint: Yup.number().typeError('Reward Points must be a number')
                    .required('Reward Points are required'),
            })
        ),
    });



    const handleSubmit = async (values: any, formikHelpers: any) => {
        const errors = await formikHelpers.validateForm();
        if (Object.keys(errors).length) {
            return;
        }
        const payload = {
            brandUuId: selectedBrandId,
            dealerUuId: selectedDealerId,
            userUuId: user.user.uuid,
            baseFormula: values.baseFormula,
            data: values.data.map((form: any) => ({
                videoAlgorithmKey: form.videoAlgorithmKey,
                videoAlgorithmCondition: form.videoAlgorithmCondition,
                videoAlgorithmParams: form.videoAlgorithmCondition == 'between' || form.videoAlgorithmCondition == 'calculate_btw' ? `${form.videoAlgorithmParams},${form.videoAlgorithmParamsbetween}` : form.videoAlgorithmParams,
                videoAlgorithmRewardPoint: parseFloat(form.videoAlgorithmRewardPoint),
                status: form.status,
                ...(form.status === 'DISABLED' && { videoAlgorithmCustomCalc: form.videoAlgorithmCustomCalc })
            })),
        };
        if (totalRewardPoints !== 100) {
            toast.error(`Total of reward points must be 100,Total reward point is ${totalRewardPoints}`, {
                position: "top-right",
                autoClose: 2000
            });
            return;
        }
        if (totalRewardPoints == 100) {
            dispatch(updateVideoScoringHandler(payload)).then((response: any) => {
                if (response?.type === `${UPDATE_VIDEO_SCORING}/${FULFILLED}`) {
                    toast.success("Video scoring algorithm updated successfully", {
                        position: "top-right",
                        autoClose: 2000
                    });
                    navigate(routeConstant.VIDEOS);
                } else {
                    toast.error("Some error occur", {
                        position: "top-right",
                        autoClose: 2000
                    })
                }
            })
        }
    };

    const setTriggerfn = (data: any) => {
        setTrigger(true);
        setTimeout(() => {
            setTrigger(false);
        }, 500);
    };

    const handleVideoScoringAlgorithmData = async (dealerId: any) => {
        await dispatch(getVideosScoreAlgorithmByBrandDealersHandler(dealerId)).then((res: any) => {
            const updatedData = res.payload.map((item: any) => {
                if (item.videoAlgorithmCondition === 'between' || item.videoAlgorithmCondition === 'calculate_btw' && item.videoAlgorithmParams.includes(',')) {
                    const [firstValue, secondValue] = item.videoAlgorithmParams.split(',');
                    return {
                        ...item,
                        videoAlgorithmParams: firstValue,
                        videoAlgorithmParamsbetween: secondValue,
                    };
                }
                return item;
            });
            setInitialValues((prevState) => ({
                ...prevState,
                baseFormula: updatedData[0]?.videoScoringAlgorithm?.baseFormula || prevState.baseFormula,
                data: updatedData,
            }));
        }).catch((err: any) => {
            toast.error("Some error occur", {
                position: "top-right",
                autoClose: 2000
            })
        });
    };

    useEffect(() => {
        handleVideoScoringAlgorithmData(selectedDealerId);
    }, [selectedDealerId]);


    return (
        <div className="min-vh-100 container-fluid">
            <div className="mx-lg-5">
                <div className="mt-5 mb-4 pb-2">
                    <Header setTrigger={setTriggerfn} />
                </div>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                    enableReinitialize={true}
                    validateOnBlur={true}
                    validateOnChange={true}
                >
                    {({ values }) => (
                        <div className="row mb-5">
                            <div className='col-4'><h3>Video Scoring Algorithm</h3></div>
                            <div className='col-4 text-end mt-2'><label>Total Reward points :{totalRewardPoints}</label></div>
                            <div className='col-1 text-end mt-2'><label>Base :</label></div>
                            <div className='col-3'>
                                <Field
                                    as="select"
                                    name="baseFormula"
                                    className="form-select"
                                >
                                    {baseOptions.map(({ value, label }: any) => (
                                        <option key={value} value={value}>
                                            {label}
                                        </option>
                                    ))}
                                </Field>
                            </div>
                            <div className="upload-area">
                                <Form>
                                    <FieldArray
                                        name="data"
                                        render={({ push, remove }) => (
                                            <>
                                                {values.data.map((_, index) => (
                                                    <div key={index}>
                                                        <VideoScoringForm
                                                            index={index}
                                                            addForm={() =>
                                                                push({
                                                                    videoScoringAlgorithmId: 0,
                                                                    videoAlgorithmKey: '',
                                                                    videoAlgorithmCondition: '',
                                                                    videoAlgorithmParams: '',
                                                                    videoAlgorithmParamsbetween: 0,
                                                                    videoAlgorithmRewardPoint: '',
                                                                    videoAlgorithmCustomCalc: '',
                                                                    status: 'ENABLED'
                                                                })
                                                            }
                                                            removeForm={() => remove(index)}
                                                            values={values}
                                                            setTotalRewardPoints={setTotalRewardPoints}
                                                        />
                                                    </div>
                                                ))}
                                            </>
                                        )}
                                    />
                                    <button type="submit" className="submit-btn">
                                        Submit
                                    </button>
                                    <p><label className='text-danger mt-2'>Note : Total of reward points must be 100.</label>
                                    </p>
                                </Form>
                            </div>
                        </div>
                    )}
                </Formik>
            </div>
        </div>
    );
};

export default VideoScoring;
