import { api } from '../common/axiosInstance';
const API_URL = process.env.REACT_APP_API_URL;

export const getTireDetails: any = async (data: any) => {
  const { brandUuid, dealerUuid, pageLimit, pageNumber } = data;
 
  try {
    const response = await api.get(`${API_URL}tire-scan/getTireScan`, {
      params: {
        dealerUuid,
        brandUuid,
        pageLimit,
        pageNumber
      }
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching user data:", error);
    throw error;
  }
};

export const getTireScanTypeDetailById:any= async (data:any) => {
  const Uuid = data.uuid;
    try {
      const response = await api.get(`${API_URL}tire-scan/get-details?scanId=${Uuid}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching user data:", error);
      throw error;
    }
};

export const updateManualTreadDepth = async(data:any)=>{
  try {
      const response = await api({
        url: `${API_URL}tire-scan/update-manual-depth`,
        data,
        method: "put",
      });
      return response.data;
    } catch (error) {
      throw error;
    }
}

export const deleteTire = async(uuid:any)=>{
 try {
       const response = await api({
         url: `${API_URL}tire-scan/delete-tire-scan-details?tireScanId=${uuid}`,
         method: "delete",
       });
       return response.data;
     } catch (error) {
       console.error("Error deleting video:", error);
       throw error;
     }
}