import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import "./Signin.css";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as ACTION from "../../Store/actions/index";
import { useFormik } from "formik";
import {
  adminHandler,
  signInHandler,
  signInMessageHandler,
  userHandler,
} from "../../Store/slices/authSlice";
import { TextField, IconButton, InputAdornment } from "@mui/material";
import { faEye, faEyeSlash, faShield } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  getAllUserBrandsHandler,
  getUserDealersHandler,
} from "../../Store/slices/dealerSlice";
import Logo from "../../../assets/images/logo.png";
import AuthWrapper from "../../../components/AuthWrapper";

const Signin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const token = localStorage.getItem("token");
  const [rememberMe, setRememberMe] = useState(false);

  const secretKey = process.env.REACT_APP_KEY;
  const salt = new TextEncoder().encode("some_random_salt");

  const getKey = async (secret: any) => {
    const keyMaterial = await crypto.subtle.importKey(
      "raw",
      new TextEncoder().encode(secret),
      "PBKDF2",
      false,
      ["deriveKey"]
    );

    return crypto.subtle.deriveKey(
      {
        name: "PBKDF2",
        salt: salt,
        iterations: 100000,
        hash: "SHA-256",
      },
      keyMaterial,
      { name: "AES-CBC", length: 256 },
      false,
      ["encrypt", "decrypt"]
    );
  };

  const encryptPassword = async (password: any, key: any) => {
    const iv = crypto.getRandomValues(new Uint8Array(16));
    const encrypted = await crypto.subtle.encrypt(
      { name: "AES-CBC", iv },
      key,
      new TextEncoder().encode(password)
    );
    return {
      iv: Array.from(iv),
      password: Array.from(new Uint8Array(encrypted)),
    };
  };

  const decryptPassword = async (encrypted: any, iv: any, key: any) => {
    const decrypted = await crypto.subtle.decrypt(
      { name: "AES-CBC", iv: new Uint8Array(iv) },
      key,
      new Uint8Array(encrypted)
    );
    return new TextDecoder().decode(decrypted);
  };

  useEffect(() => {
    if (token) {
      navigate("/dashboard");
    }
    const rememberedEmail = localStorage.getItem("email");
    const encryptedPassword = localStorage.getItem("password");
    const rememberedIV = localStorage.getItem("iv");

    if (rememberedEmail && encryptedPassword && rememberedIV) {
      getKey(secretKey).then(async (key) => {
        const decryptedPassword = await decryptPassword(
          JSON.parse(encryptedPassword),
          JSON.parse(rememberedIV),
          key
        );
        formik.setValues({
          ...formik.values,
          email: rememberedEmail,
          password: decryptedPassword,
        });
        setRememberMe(true);
      });
    }
  }, [token, navigate]);

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required("Email is required")
      .email("Please enter valid email address"),
    password: Yup.string()
      .required("Password is required")
      .transform((value, originalValue) => {
        if (typeof originalValue === "string") {
          return originalValue.trim();
        }
        return value;
      }),
  });

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleRememberMe = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRememberMe(event.target.checked);
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      await dispatch(signInHandler(values)).then((res: any) => {
        if (res?.type === `${ACTION.LOGIN}/${ACTION.FULFILLED}`) {
          if (res.payload.roleUser == false) {
            navigate(`/admin/dashboard`);
            dispatch(adminHandler(res?.payload));
            localStorage.setItem("token", res?.payload?.token);
            toast.success("Login Succesfull!", {
              position: "top-right",
              autoClose: 3000,
            });
          } else {
            if (res?.payload?.user.forceChngPwd === true) {
              dispatch(userHandler(res?.payload));
              navigate(`/changepassword/${res?.payload?.user.uuid}`);
              const today: any = new Date();
              const dbDateObj: any = new Date(
                res?.payload?.user?.lastPwdChngAt
              );
              const timeDiff = Math.abs(today - dbDateObj);
              const dayDiff = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
              if (res?.payload?.user?.lastPwdChngAt == null) {
                toast.warning("Please Change Your Password", {
                  position: "top-right",
                  autoClose: 3000,
                });
              } else if (dayDiff >= 90) {
                toast.warning(
                  "You need to change your password as last password was changed about 90 days ago",
                  {
                    position: "top-right",
                    autoClose: 3000,
                  }
                );
              }
            } else {
              localStorage.setItem("token", res?.payload?.token);
              dispatch(userHandler(res?.payload));
              let userPayload = res?.payload?.user;
              dispatch(getAllUserBrandsHandler(userPayload.uuid)).then(
                (res: any) => {
                  let brandId = res.payload?.data[0]?.uuid;
                  let userId = userPayload.uuid;
                  dispatch(
                    getUserDealersHandler({ userId, brand: brandId })
                  ).then((res: any) => {
                    if (res.payload.statusCode == 200) {
                      navigate("/dashboard");
                    }
                  });
                }
              );

              toast.success("Login successfully", {
                position: "top-right",
                autoClose: 2000,
              });
              if (rememberMe) {
                const keyPromise = getKey(secretKey);
                keyPromise.then(async (key) => {
                  const { iv, password: encryptedPassword } =
                    await encryptPassword(values.password, key);
                  localStorage.setItem("email", values.email);
                  localStorage.setItem(
                    "password",
                    JSON.stringify(encryptedPassword)
                  );
                  localStorage.setItem("iv", JSON.stringify(iv));
                });
              } else {
                localStorage.removeItem("email");
                localStorage.removeItem("password");
                localStorage.removeItem("iv");
              }
            }
          }
        } else {
          if (res?.type === `${ACTION.LOGIN}/${ACTION.REJECTED}`) {
            dispatch(
              signInMessageHandler(res?.payload?.response?.data?.message)
            );
            toast.error(res?.payload?.response?.data?.message, {
              position: "top-right",
              autoClose: 2000,
            });
          } else {
            toast.error("Login failed");
          }
        }
      });
    },
  });

  return (
    <>
      <AuthWrapper title="Sign in" icon={faShield}>
        <form onSubmit={formik.handleSubmit} className="vstack gap-4">
          <TextField
            fullWidth
            id="email"
            name="email"
            label="Email"
            size="small"
            variant="standard"
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />

          <TextField
            fullWidth
            id="password"
            name="password"
            label={"Password"}
            size="small"
            variant="standard"
            type={showPassword ? "text" : "password"}
            value={formik.values.password}
            onChange={formik.handleChange}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    <FontAwesomeIcon
                      icon={showPassword ? faEyeSlash : faEye}
                      size="xs"
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <div className="hstack justify-content-between gap-3 flex-wrap">
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                id="rememberme"
                onChange={handleRememberMe}
                checked={rememberMe}
              />
              <label className="form-check-label" htmlFor="rememberme">
                Remember me
              </label>
            </div>
            <p className="forgotpsd">
              <Link to="/forgot-password" className="text-decoration-none">
                {"Forgot Password?"}
              </Link>{" "}
            </p>
          </div>

          <div>
            <button
              type="submit"
              className="btn btn-primary rounded-4 px-4 py-2 w-100"
            >
              Login
            </button>
          </div>
        </form>
      </AuthWrapper>
    </>
  );
};

export default Signin;
