import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { deleteTire, getTireDetails, getTireScanTypeDetailById, updateManualTreadDepth } from '../../../services/tire-scan';
import * as actions from "../actions/index";

export interface TireScan {
    tireDetails: any[],
    tireSideWallDetails: any[],
    tireTreadDetails: any[]
}

const initialState: TireScan = {
    tireDetails: [],
    tireSideWallDetails: [],
    tireTreadDetails: []
}

export const tireDetailsHandler = createAsyncThunk(
    actions.GETTYRESCAN,
    async (data: any, { rejectWithValue }) => {
        try {
            const response = await getTireDetails(data);
            return response;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const tireDetailsByIdHandler = createAsyncThunk(
    actions.GETTIRESCANTYPEDETAIL,
    async (data: any, { rejectWithValue }) => {
        try {
            const response = await getTireScanTypeDetailById(data);
            return response;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const updateManualTreadDepthHandler = createAsyncThunk(
    actions.UPDATEMANUALTREADDEPTH,
    async (data: any, { rejectWithValue }) => {
        try {
            const response = await updateManualTreadDepth(data);
            return response;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const deleteTireHandler = createAsyncThunk(
    actions.DELETETIRE,
    async (uuid: any, { rejectWithValue }) => {
        try {
            const response = await deleteTire(uuid);
            return response;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const tireSlice = createSlice({
    name: 'tireSlice',
    initialState,
    reducers: {}
})

export const { } = tireSlice.actions;
export default tireSlice.reducer;