import { ErrorMessage, Field } from 'formik';
import { TextField } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { videoAlgorithmCondition, videoAlgorithmKey,conditionMapping } from '../../common/common';
import FormField from '../../components/FormField';
import { useEffect, useState } from 'react';

interface VideoScoringFormProps {
    index: number;
    removeForm: () => void;
    addForm: () => void;
    values: {
        baseFormula: string,
        data: Array<{
            videoScoringAlgorithmId?: number;
            videoAlgorithmKey?: string;
            videoAlgorithmCondition?: string;
            videoAlgorithmParams?: string;
            videoAlgorithmParamsbetween?: number;
            videoAlgorithmRewardPoint?: string;
            videoAlgorithmCustomCalc: string,
            status: string,
        }>;
    };
    setTotalRewardPoints:any;
}

const VideoScoringForm: React.FC<VideoScoringFormProps> = ({ index, removeForm, addForm, values ,setTotalRewardPoints}) => {
    const [filteredConditions, setFilteredConditions] = useState(videoAlgorithmCondition);


    const countTotalRewardPoints = () => {
        const rewardPointsWithoutIn = values.data
            .filter((item: any) => item.videoAlgorithmCondition !== "in")
            .map((item: any) => {
                const rewardPoint = parseFloat(item.videoAlgorithmRewardPoint) || 0;
                return rewardPoint;
            })
            .reduce((acc: any, curr: any) => acc + curr, 0);
    
        const rewardPointsForIn = values.data
            .filter((item: any) => item.videoAlgorithmCondition === "in")
            .map((item: any) => {
                const inParamsCount = item.videoAlgorithmParams || "";
                const rewardPoints = parseFloat(item.videoAlgorithmRewardPoint) || 0;
                const splitArray = inParamsCount.split(",");
                const totalParams = splitArray.filter((param: string) => param.trim() !== "").length;
                return totalParams * rewardPoints;
            })
            .reduce((acc: any, curr: any) => acc + curr, 0);
        
        let totalRewardPoints = rewardPointsWithoutIn + rewardPointsForIn;
    
        if (values.baseFormula === "MAXIMUM") {
            totalRewardPoints = 100 - totalRewardPoints;
        }
        setTotalRewardPoints(totalRewardPoints);
    };
    
    useEffect(() => {
        countTotalRewardPoints();
    }, [values]);
    
    const currentData = values.data[index];
    const isAllFieldsEmpty = index === 0 &&
        !currentData.videoAlgorithmKey &&
        !currentData.videoAlgorithmCondition &&
        !currentData.videoAlgorithmParams &&
        !currentData.videoAlgorithmRewardPoint;
    const isDisabled = values.data[index].status == "DISABLED";
    const isInExist = values.data[index].videoAlgorithmCondition == "in";

    const getTotalForIn: any = () => {
        if (isInExist) {
            const inParamsCount: any = values.data[index].videoAlgorithmParams;
            const rewardPoints: any = values.data[index].videoAlgorithmRewardPoint;
            const splitArray = inParamsCount.split(",");
            const totalParams = splitArray.length;
            const totalPerKey = totalParams * rewardPoints;
            return totalPerKey;
        }
    }
    
    // Update filtered conditions based on the selected algorithm key
    const handleKeyChange = (key: keyof typeof conditionMapping) => {
        const validConditions = conditionMapping[key] as readonly string[]; 
        const updatedConditions = videoAlgorithmCondition?.filter((condition:any) =>
            validConditions?.includes(condition.value)
        );
        setFilteredConditions(updatedConditions);
    };

    // to Call the handlekeyChange when key change
    useEffect(() => {
        if (currentData.videoAlgorithmKey) {
            handleKeyChange(currentData.videoAlgorithmKey as keyof typeof conditionMapping);
        }
    }, [currentData.videoAlgorithmKey]);


    const renderParamsField = () => {
        if (currentData.videoAlgorithmCondition === 'between' || currentData.videoAlgorithmCondition === 'calculate_btw') {
            return (
                <div style={{ display: 'flex', gap: '10px' }}>
                    <Field
                        as={TextField}
                        fullWidth
                        id={`data.${index}.videoAlgorithmParams`}
                        name={`data.${index}.videoAlgorithmParams`}
                        size="small"
                        variant="outlined"
                        placeholder="Start"
                        disabled={isDisabled}
                    />
                    <Field
                        as={TextField}
                        fullWidth
                        id={`data.${index}.videoAlgorithmParamsbetween`}
                        name={`data.${index}.videoAlgorithmParamsbetween`}
                        size="small"
                        variant="outlined"
                        placeholder="End"
                        disabled={isDisabled}
                    />
                </div>
            );
        }
        return (
            <Field
                as={TextField}
                fullWidth
                id={`data.${index}.videoAlgorithmParams`}
                name={`data.${index}.videoAlgorithmParams`}
                size="small"
                variant="outlined"
                disabled={isDisabled}
            />
        );
    };

    return (
        <div className="form-row">
             {/* Algorithm Key Field */}
             <FormField
                name={`data.${index}.videoAlgorithmKey`}
                fieldAs="select"
                title="Algorithm Key"
                isDisabled={isDisabled}
                options={videoAlgorithmKey}
            />

            {/* Algorithm Condition Field */}
            <FormField
                name={`data.${index}.videoAlgorithmCondition`}
                fieldAs="select"
                title="Algorithm Condition"
                isDisabled={isDisabled}
                options={filteredConditions}
            />
            {isDisabled &&
                <FormField
                    name={`data.${index}.videoAlgorithmCustomCalc`}
                    fieldAs='TextField'
                    title='Extra Calculations'
                    isDisabled={isDisabled}
                />
            }
            <div className="form-field">
                <label htmlFor={`data.${index}.videoAlgorithmParams`}>Algorithm Params:</label>
                {renderParamsField()}
                <ErrorMessage
                    name={`data.${index}.videoAlgorithmParams`}
                    component="div"
                    className="text-danger txt-error"
                />
            </div>
            <div className="form-field">
                <FormField
                    name={`data.${index}.videoAlgorithmRewardPoint`}
                    fieldAs='TextField'
                    title={values.baseFormula == 'ZERO' ? 'Reward Points' : 'Loss Points'}
                    isInExist={isInExist}
                    getTotalForIn={getTotalForIn()}
                />
            </div>
            {index == 0 &&
                <button
                    type="button"
                    className="submit-btn add-btn"
                    onClick={addForm}
                    disabled={isAllFieldsEmpty}
                >
                    <FontAwesomeIcon icon={faPlus} />
                </button>
            }
            {isDisabled || index !== 0 &&
                <button
                    type="button"
                    className="submit-btn remove-btn"
                    onClick={removeForm}
                >
                    <FontAwesomeIcon icon={faMinus} />
                </button>
            }
        </div>
    );
};

export default VideoScoringForm;
